import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Watches for Sale'}
      canonical={'/fine-watches/rolex/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-header.png"
                alt="Pre-Owned Certified Used Rolex Watches Header"
                aria-label="Used Rolex Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX WATCHES</h2>
            <p>
              Gray and Sons Jewelers is considered #1 for buying and selling Rolex watches. We are
              nationally known and located in Miami, Florida. Gray and Sons is known for our watch
              and jewelry experts including used Rolex watch experts who are on-site, 6 days a week.
              We're a real, not a virtual or pop-up store, proudly assisting our customers for the
              past 42 years. We buy, sell, trade and repair used and pre-owned Rolex watches.
              Servicing the entire USA, Miami, Miami Beach, Coral Gables, Coconut Grove, South
              Beach, Bal Harbour, Surfside, Sunny Isles, Brickell, Pinecrest, Aventura, Fort
              Lauderdale and many more areas. Online at <a href="/">www.grayandsons.com</a> or visit
              us in store at our watch and jewelry boutique showroom across from the Bal Harbour
              Shops. We sell Rolex: pre-owned, estate, vintage, antique, modern, heirloom and used
              Rolex watches.
              <br />
              <br />
              Of all the luxury watchmakers out there, Rolex stands out as the leader of the pack.
              It’s a brand that is synonymous with prestige, luxury, and accomplishment. The
              pre-owned Rolex market is a robust one, with plenty of models valued well above retail
              prices. But how can Rolex for sale in the secondary market be more expensive than
              retail prices, you ask? The answer is simple: the demand for Rolex watch models –
              particularly Rolex men models – outstrips supply.
              <br />
              <br />
              In short, the immense popularity of Rolex men watches means that they quickly sell out
              at authorized dealers. However, as an independent Rolex dealer, Gray &amp; Sons not
              only boasts a large inventory of Rolex watches for men available for sale right now,
              but we also have price flexibility when buying or selling pre-owned Rolex watch
              references.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Watch Models</b>
              <br />
              Some of the most popular used Rolex watches in the market include the Daytona,
              Submariner, GMT-Master, Sea-Dweller, Explorer, Yacht-Master, Sky-Dweller, Day-Date
              President, Datejust, and Oyster Perpetual watches.Whether you’re looking to buy or
              sell used Rolex watches, Gray &amp; Sons can help. To buy a used Rolex, get in touch
              with one of our <a href="/chat-with-rich">decision-makers</a>
              right now to make an offer. Alternatively, if you’re wondering, where should I sell my
              Rolex watch, visit <a href="https://sellusyourjewelry.com/">
                Sell Us Your Jewelry
              </a>{' '}
              for the best cash offer in the market. We offer certified pre-owned watches.
              <br />
              <br />
              <b>Why Gray &amp; Sons is The Best Source for Buying and Selling Rolex</b>
              <br />
              Unlike so many virtual e-commerce stores with inexperienced staff cluttering the
              online space, Gray &amp; Sons is a real brick-and-mortar store located in the
              Surfside/Miami area, right across from the world-famous Bal Harbour Shops. We are open
              six days a week and our flagship store is home to a team of highly knowledgeable watch
              and jewelry experts on-site. Our watch specialists are ready to assist you when you’d
              like to buy a pre-owned Rolex or sell a used Rolex watch. WE BUY, SELL, TRADE, AND
              REPAIR USED AND PRE OWNED ROLEX WATCHES, and we are happy to work with you. For more
              details, visit our showroom in Surfside, Miami, or check our website{' '}
              <a href="/">www.grayandsons.com</a>.
              <br />
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex/">
                <button>SHOP ROLEX WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexMain
